import React from 'react'
import getWindowDimention from '../../functions/getWindowDimentions'

export default function Brain({data}) {
    const {width} = getWindowDimention()
    let brainBottom 
    if(width < 600){
      brainBottom = '180px'
    }else{ 
      brainBottom = '350px'

    }

    let {move, size} = data; 

    
    let wid = size? size*7+ 140 : ''
    
    // let top = size? 350 - size*3.5 +'px':''
    // let right = size? 50 - size + '%' : ''
    let top= size? "50%":''
    let right = size? 'calc(25% + 290px)':""
    if(size){
      move = '50%'
    }
    if(size >70){
      var fade = 1 - ( (size -70) /30)
    }
    // console.log(size?'-'+ (width/2 )+'px':'');
    
    return (
        <div>
            <svg  id="brain" 
            style={{
              animation: move? 'none':'',
              bottom:(move?`calc(${-move*50+'%'} + ${brainBottom})`:'')+(size? right: ''),
              width:size? wid:'',
              height:size? wid:'',
              top:size?top:'',
              margin:size? '-'+ (wid/2 )+'px': '',
              opacity: fade>=0? fade :''
              // marginLeft:size? '-'+ (width/2) + 'px':''
            }} 
            
            xmlns="http://www.w3.org/2000/svg" width="191.809" height="163.03" viewBox="0 0 191.809 163.03">

  
  <g  transform="matrix(0.998, 0.07, -0.07, 0.998, 8.309, -4.426)">
    <path id="Path_285" data-name="Path 285" class="cls-1" d="M1.488,48.238c-10.783,39.917,40.569,55.357,40.569,55.357s18.41-.23,30.667-12.969,5.24-15.912,5.24-15.912-13.4-9.006-22.515-5.9-25.9,6.107-32.255-1.346a21.768,21.768,0,0,1-4.856-16.4,14.672,14.672,0,0,0-4.089-15.716C6.674,28.147,7.24,16.73,7.24,16.73S11.1,8.221,14.249,6.266,11.333,0,11.333,0" transform="translate(3.542 57.895)"/>
    <path id="Path_286" data-name="Path 286" class="cls-1" d="M4.723,95.2l.422.981c-.029-.026-15.138-14.035,6.562-57.247C33.429-4.323,66.031-3.081,70.955,3.287S81.7,38.932,81.7,38.932s.5,8.782-7.852,6.555-19.174-3.946-20.537-19.51-20.937-3.939-16.225.93" transform="translate(0 10.111)"/>
    <path id="Path_287" data-name="Path 287" class="cls-1" d="M0,9.249S6.658,5.377,4.961,0" transform="translate(52.204 22.761)"/>
    <path id="Path_288" data-name="Path 288" class="cls-1" d="M0,36.494s2.676,7.141,13.029,0,3.55-21.037,3.55-21.037-6.217-9.967,2.642-14S31.763,3.019,31.763,3.019" transform="translate(81.708 13.753)"/>
    <path id="Path_289" data-name="Path 289" class="cls-1" d="M9.319,7.167S-1.234,8.452.12,0" transform="translate(87.562 12.91)"/>
    <path id="Path_290" data-name="Path 290" class="cls-1" d="M0,3.557S7.808-5.28,10.357,4.9" transform="translate(77.035 25.315)"/>
    <path id="Path_292" data-name="Path 292" class="cls-1" d="M55.948,10.2c-1.754.192-3.435-3.64-3.435-3.64S45.22-5.687,34.3,3.285s-6.348,23.6-6.348,23.6,6.471,7.038-1.471,14.976S7.09,50.995,7.09,50.995-1.31,57.051.176,64.517s5.287,20.4,9.31,21.31,9.581,6.229,19.756-8.815S48.479,62.025,48.479,62.025,65.351,58.845,69.723,48.5,65.6,34.755,65.6,34.755,54.819,36.282,52.32,44.367s-9.94,5.082-9.94,5.082" transform="translate(96.546 40.767)"/>
    <path id="Path_293" data-name="Path 293" class="cls-1" d="M0,0S6.543,6.628,13.872,2.094" transform="translate(125.489 69.814)"/>
    <path id="Path_294" data-name="Path 294" class="cls-1" d="M2.122,19.365S-4.539,6.339,5.742,0" transform="translate(106.671 55.867)"/>
    <path id="Path_295" data-name="Path 295" class="cls-1" d="M10.53,2.284S5.136-1.274,0,.491" transform="translate(96.141 62.901)"/>
    <path id="Path_296" data-name="Path 296" class="cls-1" d="M0,0S9.5.216,11.239,7.606" transform="translate(137.974 58.47)"/>
    <path id="Path_297" data-name="Path 297" class="cls-1" d="M18.221,7.707s-.735-6.515-6.6-7.562S2.094,4.051,2.094,4.051-.45,8.19.07,9.523" transform="translate(111.463 36.24)"/>
    <path id="Path_298" data-name="Path 298" class="cls-1" d="M4.275,6.974A7.853,7.853,0,0,1,0,0" transform="translate(114.088 30.013)"/>
    <path id="Path_299" data-name="Path 299" class="cls-1" d="M0,12.15S2.569,2.054,9.981,0" transform="translate(112.412 99.562)"/>
    <path id="Path_300" data-name="Path 300" class="cls-1" d="M25.356,50.366s-8.04-13.3-6.193-21.259,2.646-15.541-4.239-23.015S0,.124,0,.124" transform="translate(140.956 29.031)"/>
    <path id="Path_302" data-name="Path 302" class="cls-1" d="M0,61.712s13.257,17.854,35.8,9.936S63.427,38.871,63.427,38.871s5.1-14.216-1.6-26.736S52.653.035,52.653.035,42.012-1.081,40.082,9.353c-.012.045.3-.756.252-.355" transform="translate(122.393 61.144)"/>
    <path id="Path_303" data-name="Path 303" class="cls-1" d="M47.773,48.954s6.2-9.728-16.915-32.939S.738,1.659.738,1.659-2.43,9.744,4.358,13.72" transform="translate(127.491 12.191)"/>
    <path id="Path_304" data-name="Path 304" class="cls-1" d="M61.613,12.191S31.383-4.438,16.254,1.147,5.584,5.189,0,13.842" transform="translate(71.317 0)"/>
    <path id="Path_305" data-name="Path 305" class="cls-1" d="M0,2.413S9.363-4.576,17.45,5.236" transform="translate(4.912 104.124)"/>
    <path id="Path_306" data-name="Path 306" class="cls-1" d="M0,.639S10.323-4.2,10.756,12.756" transform="translate(15.647 41.564)"/>
    <path id="Path_307" data-name="Path 307" class="cls-1" d="M0,6.783S5.964-.944,11.95.1a6.3,6.3,0,0,1,4.812,3.315A7.506,7.506,0,0,0,18.046,4.7s6.881,4.6,12.719-2.828" transform="translate(22.362 109.179)"/>
    <path id="Path_308" data-name="Path 308" class="cls-1" d="M0,.888S2.779,10.3,11.366,6.758,14.619,0,14.619,0" transform="translate(70.343 110.824)"/>
    <path id="Path_309" data-name="Path 309" class="cls-1" d="M61.954,62.826s20.207,4.635,17.447-13.1S74.642,26.474,56.87,27.935,36.266,28.944,31.284,13.4,11.173-.817,7.818,1.189,3.5,10.723,3.5,10.723s-.574,5.97-3.5,5.271" transform="translate(20.487 70.108)"/>
    <path id="Path_310" data-name="Path 310" class="cls-1" d="M.012,13.691S-.579,6.284,6.419,0" transform="translate(31.621 85.871)"/>
    <path id="Path_311" data-name="Path 311" class="cls-1" d="M0,13.229S12.36,9.735,9.93,0" transform="translate(42.311 84.813)"/>
    <path id="Path_312" data-name="Path 312" class="cls-1" d="M0,0S7.476,9.524,19.659,7.4" transform="translate(128.377 114.896)"/>
    <path id="Path_313" data-name="Path 313" class="cls-1" d="M0,.012s8.939-.5,11.606,4.962S14.125,19.115,5.3,19.863" transform="translate(158.392 97.969)"/>
    <path id="Path_314" data-name="Path 314" class="cls-1" d="M0,0A5.684,5.684,0,0,0,4.73,5.481a10.04,10.04,0,0,1,6.652,4.472S13.345,17.4,4.73,19.3" transform="translate(151.99 107.215)"/>
    <path id="Path_315" data-name="Path 315" class="cls-1" d="M0,.934S12.857-3.909,15.557,8.463" transform="translate(166.312 87.462)"/>
    <path id="Path_316" data-name="Path 316" class="cls-1" d="M0,10.884S4.491,7.411,2.12,0" transform="translate(178.452 79.029)"/>
    <path id="Path_317" data-name="Path 317" class="cls-1" d="M2.638,14.338s-5.935-8.167,0-11.925,10.589-2,10.589-2,4.4,1.627,6.28,4.412" transform="translate(33.975 56.327)"/>
    <path id="Path_318" data-name="Path 318" class="cls-1" d="M0,0A5.9,5.9,0,0,1,2.681,2.46c1.311,2.047,2.311,5.889-.434,6.959" transform="translate(40.352 47.113)"/>
    <path id="Path_319" data-name="Path 319" class="cls-1" d="M0,0S11.562,1.707,10.121,15.894" transform="translate(60.317 70.108)"/>
    <path id="Path_320" data-name="Path 320" class="cls-1" d="M0,7.019S-.134,1.748,8.426,0" transform="translate(68.231 68.213)"/>
    <path id="Path_321" data-name="Path 321" class="cls-1" d="M0,0A10.089,10.089,0,0,1,7.421,7.328C9.142,13.626,13.2,30.2,13.2,30.2S11.592,39.153,2.862,42.454" transform="translate(77.671 55.474)"/>
    <path id="Path_322" data-name="Path 322" class="cls-1" d="M0,0S5.185,8.137,13.321,7.125,24.312,4.108,24.312,4.108" transform="translate(90.182 84.506)"/>
    <path id="Path_323" data-name="Path 323" class="cls-1" d="M0,18.135s-.155-5.976,5.113-8.077S15.66,4.136,15.85,0" transform="translate(84.269 43.009)"/>
    <path id="Path_324" data-name="Path 324" class="cls-1" d="M10.991,0S13.567,13.808,0,17.49" transform="translate(63.238 128.245)"/>
    <path id="Path_325" data-name="Path 325" class="cls-1" d="M8.015,0A5.927,5.927,0,0,1,5.348,6.778C1.257,9.467.714,9.48.04,13.308s7.289,7.132,11.7,5.589" transform="translate(43.286 135.371)"/>
    <path id="Path_326" data-name="Path 326" class="cls-1" d="M8.351,0S-.424,4.7.016,10.783,8.7,21.721,12.659,19.328" transform="translate(30.94 129.591)"/>
    <path id="Path_291" data-name="Path 291" class="cls-1" d="M0,1.375S7.6-2.491,12.075,2.743" transform="translate(94.916 6.273)"/>
  </g>
</svg>

        </div>
    )
}
