import React, {useEffect} from 'react';
import NavBar from './components/navBar/NavBar'
import LandingPage from './pages/landingPage/LandingPage'
import {Switch, Route} from 'react-router-dom'
import Home from './pages/home/Home'
import Skills from './pages/skills/Skills'
import Projects from './pages/projects/Projects'
import Game from './components/game/GameOver'
import NotFound from './pages/notFound/NotFound'
import ContactMe from './pages/contactMe/ContactMe'
import './styling/main.scss'
function App() {
  useEffect(() => {
    document.querySelector('.loading-container').style.display = "none"; // to remove the initial loading 
  }, [])
  return (
    <div> 
      <NavBar />  
      <Switch >
      <Route exact path="/"><LandingPage /> </Route>
      <Route path="/skills"> <Skills /> </Route>
      <Route path="/home"> <Home /> </Route>
      <Route path="/projects"> <Projects /> </Route>
      <Route path="/game"> <Game /> </Route>
      <Route path="/contactMe"> <ContactMe /> </Route>
      <Route path='*'><NotFound/></Route> 
      </Switch>
    </div>
  );
}

export default App;
