import React, {useState, useEffect} from 'react'
import outer from '../../../assets/outer.png'
import middle from '../../../assets/middle.png'
import './skill.scss'
import '../movingCircle.scss'
import style from './skill.scss'
export default function Skill({top, right, left, delay,mode, ...props}) {
    const [appear, setAppear]= useState(false)
    const topLocation = `calc(${top} + ${style.homeMargin})`
    const getLocation = (location)=> {
        return `calc( ${location} - ${style.outerWidth}/2 )`
    }
    useEffect(() => {
        setTimeout(() => {
            setAppear(true)
        }, delay || 0);
        
    }, [])
    const location = { 
        right: right? getLocation(right): '',
        left: left? getLocation(left): '',
        top: getLocation(topLocation)

    }
    return (
        <>
        {appear?
        <div className="skill" style ={props.style|| {}}>
            <img src={middle} className={"middle " + mode} alt="" style={location} />
            <img src={outer} className={"outer "+ mode} alt="" style={location} />
            <h1 className='title' style={props.title ||{}}>{props.children}</h1>
        </div>
        :''}
        </>
    )
} 
