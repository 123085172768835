import React from 'react'
import projectsImg from '../../assets/projects.png'


export default function ProjectSection() {

    return (
        <div className='project-section section'>
            <div className="text-section">
            <h2 className="section-title">My spin on<br/>projects</h2>
            <h4 className="section-subtitle">Adore the challenge</h4>
            <p className="section-paragraph">You will never reach your true potential without challenging yourself to a level that gets to your actual limitation and beyond that. By exploring the edges of your limits, you will find great joy in your work.</p>
            </div>
            <div className="section-image">
                    <img src={projectsImg} className="img" alt=""/>
                          </div>
        </div>
    )
}
