import React from 'react'
import outer from '../../../assets/outer.png'
export default function Outer({start}) {

    return (
           <>
         {/* <svg className="outer" xmlns="http://www.w3.org/2000/svg" width="262.631" height="263.558" viewBox="0 0 262.631 263.558">
  <g id="outer" transform="matrix(-0.07, 0.998, -0.998, -0.07, 262.662, 17.274)">
    <g id="Ellipse_118" data-name="Ellipse 118" transform="translate(2.888 2.04)" fill="none" stroke="#3bcfc3" stroke-width="4">
      <circle cx="120.5" cy="120.5" r="120.5" stroke="none"/>
      <circle cx="120.5" cy="120.5" r="118.5" fill="none"/>
    </g>
    <g id="Ellipse_119" data-name="Ellipse 119" transform="translate(-0.112 0.04)" fill="none" stroke="#3bcfc3" stroke-width="1">
      <ellipse cx="123.5" cy="123" rx="123.5" ry="123" stroke="none"/>
      <ellipse cx="123.5" cy="123" rx="123" ry="122.5" fill="none"/>
    </g>
    <path id="Path_204" data-name="Path 204" d="M2948.532,483.665s32.345-70.1,127.43-43.683" transform="translate(-2953.231 -100.268) rotate(-6)" fill="none" stroke="#3bcfc3" stroke-width="1"/>
    <path id="Path_205" data-name="Path 205" d="M2994.193,441.955s32.761-17.576,80.1,0" transform="translate(-2951.197 -48.329) rotate(-7)" fill="none" stroke="#3bcfc3" stroke-width="3"/>
  </g>
</svg> */}

<img src={outer} alt="" className={"outer" + start}/>
</>
    )
}
