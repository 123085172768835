import React from 'react'
import angryMan from '../../../assets/angryMan1.png'
// import Typist from 'react-typist'
import './angryMan.scss'
import ReactTypingEffect from 'react-typing-effect';



export default function AngryMan({start,text, children, ...props}) {
    
    return ( 
        <div className={'angryMan'}>
        <p>
        <ReactTypingEffect
        cursor={'.'}
      text={text} speed={50} typingDelay={500} eraseDelay={props.eraseDelay}
    />

    
        </p>
         <img src={angryMan} alt=""/>
        </div>
    )
}
