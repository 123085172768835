import React, {createContext,useState,useMemo } from 'react'

const gameContext = createContext() 

export  function Game(props) {
    const [start, setStart] = useState('') 
    const startGame = (action) => {
         if(!start){ 
             setStart(' start')
             setTimeout(() => {
                 setStart(' start remove')
             }, 5000);
         }else if (action === 'back'){
            setStart('')
         }
    }
    const gameValue = useMemo(()=>[start , startGame], [start, setStart])
    return (
        <gameContext.Provider value={gameValue}>
            {props.children }
        </gameContext.Provider>
    )
}
export default gameContext

