import React,{useEffect, useState, useReducer} from 'react';
import './notFound.scss';
// import throttle from '../../functions/throttle';
import getWindowDimentions from '../../functions/getWindowDimentions'
import {useHistory } from 'react-router-dom';
import monster1 from '../../assets/monster1.png'
import monster2 from '../../assets/monster2.png'
import monster3 from '../../assets/monster3.png'
import monster4 from '../../assets/monster4.png'
var difficulty = 10
var startIt = false
var oldX  
var oldY
var min = 5
export default function NotFound() {
    const history = useHistory()
    const {width, height} = getWindowDimentions();
    let xLookOffset;
    let yLookOffset;
    yLookOffset = 300 ; 

    if(width > 600){ 

        xLookOffset = 243 - 50;
        // yLookOffset = 300 - 130; 
    } else{ 
        xLookOffset = 122;
    }
    const goHome = () => {
        history.push('/')
    }
    const superCoolshit = (state, pos) => {
        if(state.length >= difficulty){
            // console.log(state.shift());
            state.shift() 
            state.push(pos)
            return state 
        }else{
            return [...state, pos]
        }
    }
    const [monsterPos, move3oo3oo] = useReducer(superCoolshit, [])

    const monsterChase = (x,y) => {
        move3oo3oo({x,y})
    }
    // super cool chase thingi 
    // useEffect(() => {
        
    //     let e = window.event
    //    let y =  e.clientY
    //    let x = e.clientX
        
    //     // console.log(e);
    //    if(nuts){ 
    //        monsterChase(x,y)
    //    }
    // }, [window.event])
    
        
  
    let horizentalOffset = width/2  - xLookOffset; 
    
    const [nuts, setNuts] = useState(false);
    const goNuts = () => {
        setTimeout(() => {
            setNuts(true);  
            startIt = true 
        }, 500);
    }

    const [xEye, setXEye] = useState(15);
    const [yEye, setYEye] = useState(15);
    
    const moveIt = (e) => {

        let y = e.clientY; 
        let x = e.clientX; 
            let newY
            let newX
            if(x> horizentalOffset){ 
                let rightHalf = width - horizentalOffset;
                x -= horizentalOffset ;
                let ratio = x/rightHalf;
                newX = 18 + ratio* 15;
            }else if (x <= horizentalOffset){ 
                let ratio = x/horizentalOffset;
                newX =3 +  ratio* 12;
            }
            if (y < yLookOffset){
                newY =  15 *  (y/yLookOffset)
            }else if (y > yLookOffset){ 
                let bottomHalf = height - yLookOffset;
                let ratio =( y - yLookOffset) /bottomHalf;
                newY = 18 + ratio* 12;
            }
    
         
            setYEye(newY)
            setXEye(newX)
          
  
    }
    const monsterStart = (e)=> {
        if(startIt){
      
       let y =  e.clientY
       let x = e.clientX
        
        if(oldX === x && oldY === y){
            difficulty -= 1 
        }
            
        monsterChase(x,y)
        oldX = x 
        oldY = y
        }
    } 

    // useEffect(() => {
    //    setInterval(() => {
    //        if(startIt){
    //            difficulty -= 1 
    //        }
    //    }, 400);
    // }, [])
    useEffect(() => {
        window.addEventListener('mousemove',e =>{
             moveIt(e)
            monsterStart(e)
        });
;
        return () => {
            window.removeEventListener('mousemove', ()=> console.log('cleanUp'));
        }
    }, [])

    return (
        <div className="notFound" onMouseEnter={goNuts}>
            {nuts? 
            <>
            <svg className="four nuts1" onMouseEnter={goHome} style={{top:monsterPos.length>= min? monsterPos[0]['y']: '',left:monsterPos.length>=min ? monsterPos[0]['x']:'', transform: monsterPos.length>=min  && monsterPos[monsterPos.length-1]['x'] > monsterPos[0]['x']?'' :"scaleX(-1)"}} xmlns="http://www.w3.org/2000/svg" width="149.28" height="210.874" viewBox="0 0 149.28 210.874">
  <g id="Group_109" data-name="Group 109" transform="translate(-171.687 -318.427)">
    <g id="Group_108" data-name="Group 108" transform="translate(-507 -47)">
      <path id="Path_368" data-name="Path 368" d="M11257.166,361.652l124.8.1V385.2h-31.148v51.1h-26.338l-.725-51.1-91.033-.132V361.643Z" transform="translate(-10554 140)" fill="#00ffef"/>
      <path id="Path_369" data-name="Path 369" d="M11232.687,361.652v-2.736l96.294-133.49h22.027l.091,136.5h-26.938l.04-95.354-67.067,95.462Z" transform="translate(-10554 140)" fill="#00ffef"/>
    </g>
    <g id="Group_92" data-name="Group 92" transform="translate(-552 -30)">
      <circle id="Ellipse_123" data-name="Ellipse 123" cx="25" cy="25" r="25" transform="translate(744 394)" fill="#fff"/>
      <circle id="Ellipse_124" data-name="Ellipse 124" cx="25" cy="25" r="25" transform="translate(802 394)" fill="#fff"/>
      <ellipse id="Ellipse_125" data-name="Ellipse 125" cx="3" cy="11.5" rx="3" ry="11.5" transform="translate(824 408)"/>
      <ellipse id="Ellipse_127" data-name="Ellipse 127" cx="3" cy="11.5" rx="3" ry="11.5" transform="translate(766 408)"/>
    </g>
  </g>
</svg>


<img src={monster1} alt="" className="four nuts2"  onMouseEnter={goHome} style={{top:monsterPos.length>= min? monsterPos[0]['y']: '',left:monsterPos.length>=min ? monsterPos[0]['x']:'', transform: monsterPos.length>=min  && monsterPos[monsterPos.length-1]['x'] > monsterPos[0]['x']?'' :"scaleX(-1)"}} />
<img src={monster2} alt="" className="four nuts3"  onMouseEnter={goHome} style={{top:monsterPos.length>= min? monsterPos[0]['y']: '',left:monsterPos.length>=min ? monsterPos[0]['x']:'', transform: monsterPos.length>=min  && monsterPos[monsterPos.length-1]['x'] > monsterPos[0]['x']?'' :"scaleX(-1)"}} />
<img src={monster3} alt="" className="four nuts4"  onMouseEnter={goHome} style={{top:monsterPos.length>= min? monsterPos[0]['y']: '',left:monsterPos.length>=min ? monsterPos[0]['x']:'', transform: monsterPos.length>=min  && monsterPos[monsterPos.length-1]['x'] > monsterPos[0]['x']?'' :"scaleX(-1)"}} />
<img src={monster4} alt="" className="four nuts5"  onMouseEnter={goHome} style={{top:monsterPos.length>= min? monsterPos[0]['y']: '',left:monsterPos.length>=min ? monsterPos[0]['x']:'', transform: monsterPos.length>=min  && monsterPos[monsterPos.length-1]['x'] > monsterPos[0]['x']?'' :"scaleX(-1)"}} />
</>
:
            <>
            <svg className="four" xmlns="http://www.w3.org/2000/svg" width="149.28" height="210.874" viewBox="0 0 149.28 210.874">
  <g id="Group_108" data-name="Group 108" transform="translate(-678.687 -365.427)">
    <path id="Path_368" data-name="Path 368" d="M11257.166,361.652l124.8.1V385.2h-31.148v51.1h-26.338l-.725-51.1-91.033-.132V361.643Z" transform="translate(-10554 140)" fill="#00ffef"/>
    <path id="Path_369" data-name="Path 369" d="M11232.687,361.652v-2.736l96.294-133.49h22.027l.091,136.5h-26.938l.04-95.354-67.067,95.462Z" transform="translate(-10554 140)" fill="#00ffef"/>
  </g>
</svg>
            

<div className="eyes">
    <div className="eye">
        <div className="pupil" style={{marginLeft: xEye, marginTop: yEye}}>

        </div>
    </div>
    <div className="eye">
    <div className="pupil" style={{marginLeft: xEye, marginTop: yEye}}>
            
        </div>
    </div>
</div>
</>
            }

            <h1><span style={{color:"rgba(0,0,0,0)"}}>4</span>04</h1>
            <h3>Page not found</h3>
           
        </div>
    )
}
