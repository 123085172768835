import React from 'react'
import middle from '../../../assets/middle.png'
export default function Middel({start}) {
    return (
      <>
        {/* <svg className="middle" xmlns="http://www.w3.org/2000/svg" width="212.117" height="212.117" viewBox="0 0 212.117 212.117">
        <g id="middle" transform="translate(-802.39 -275.343)">
          <g id="Ellipse_120" data-name="Ellipse 120" transform="matrix(0.995, 0.105, -0.105, 0.995, 822.563, 275.343)" fill="none" stroke="#3bcfc3" stroke-width="1">
            <circle cx="96.5" cy="96.5" r="96.5" stroke="none"/>
            <circle cx="96.5" cy="96.5" r="96" fill="none"/>
          </g>
          <g id="Group_6" data-name="Group 6" transform="translate(817.51 328.838)">
            <path id="Path_206" data-name="Path 206" d="M966.61,552.715l3.824,7.087H975.4l-2.748-7.087Z" transform="translate(-958.053 -461.223)" fill="#3bcfc3" stroke="#3bcfc3" stroke-width="1"/>
            <path id="Path_207" data-name="Path 207" d="M966.155,551.584h5.122l-3.852-14.032H961.2Z" transform="translate(-959.495 -463.096)" fill="#3bcfc3" stroke="#3bcfc3" stroke-width="1"/>
            <path id="Path_208" data-name="Path 208" d="M958.994,522.365h5.8l1.316,15.167H960.4Z" transform="translate(-958.994 -464.972)" fill="#3bcfc3" stroke="#3bcfc3" stroke-width="1"/>
            <path id="Path_209" data-name="Path 209" d="M960.553,522.863l5.819.233v-15.63L961,507.031Z" transform="matrix(0.998, 0.07, -0.07, 0.998, -921.528, -533.103)" fill="#3bcfc3" stroke="#3bcfc3" stroke-width="1"/>
            <path id="Path_210" data-name="Path 210" d="M960.415,506.331h5.826l4.255-14.4h-5.877Z" transform="translate(-958.713 -468.731)" fill="#3bcfc3" stroke="#3bcfc3" stroke-width="1"/>
            <path id="Path_211" data-name="Path 211" d="M964.277,491.442l6.345.405,5.816-13.278-6.728-.521Z" transform="translate(-958.341 -470.446)" fill="#3bcfc3" stroke="#3bcfc3" stroke-width="1"/>
            <path id="Path_212" data-name="Path 212" d="M970.906,477.124h6.946l3.4-5.842H973.7Z" transform="translate(-958.522 -471.282)" fill="#3bcfc3" stroke="#3bcfc3" stroke-width="1"/>
          </g>
          <path id="Path_213" data-name="Path 213" d="M1038.828,436.907s39.519-4.973,70.247,27.69" transform="translate(-133.623 -146.724)" fill="none" stroke="#3bcfc3" stroke-width="1"/>
        </g>
      </svg> */}


      <img src={middle} alt="" className={"middle" + start}/>
      </>
    )
}
