import React, {useReducer, useEffect, useState} from 'react'
import './game.scss'
import AngryMan from './angryMan/AngryMan'
import Render from './Render'
import GameOver from './GameOver'

export default function Game() {
    const [stop, setStop] = useState(false)
    const [start, setStart] = useState(false) //change this to false

    const addScore = (state, action) => { 
         if (action === 'reset') {
            return 0 
        }else
        if(!stop && start === true){

            return state + 1

        }
      else{
            return state
        }
    }
    const [score, incrementScore] = useReducer(addScore, 0)
    useEffect(() => {
        setTimeout(() => {
            setStart('te')
        }, 9500);
        const interval = setInterval(() => {
            incrementScore()

        }, 110);
        return () => clearInterval(interval);
      }, []);
    const gameOver = () => { 
        if(!stop){ 
            setStop(true)
        }
    }
    const startGame = () =>{ 
        setTimeout(() => {
        setStart(true)
        // alert(score)
        }, 50);
    }
    const playAgain = () => {
        incrementScore('reset')
        setStart(true)
        setStop(false)
        // console.log({start, stop});
        
    }



    return (
        <>
            {start? 
                <div className="game" onMouseLeave={gameOver} onMouseOver={startGame}>

                    

                 
                    {start === true && !stop ?
                    <>
                    <Render score={score} gameOver={gameOver} /> 
                    <div className="top"><h1 style={{textAlign:'center'}}>{score}</h1></div>
                    <div className="left" onMouseEnter={gameOver}></div>
                    <div className="right" onMouseEnter={gameOver}></div>
                    <div className="bottom" onMouseEnter={gameOver}></div>
                        </>
                    :''}
                    {stop? <GameOver playAgain={playAgain} score={score}/>:''}
            
               </div>
            :
            <AngryMan eraseDelay={1000} text={['WHYYY? Why would you click that button??!', 'DO NOT TOUCH THE BLACK SQUARES!']}/>
            }
       </>
    )
}
