import React from 'react'
import center from '../../../assets/center.png'
export default function Center({start}) {

  
    return (
      <>
        {/* <svg className="center" xmlns="http://www.w3.org/2000/svg" width="155.263" height="156.136" viewBox="0 0 155.263 156.136">
        <g id="center" transform="translate(0.336 17.443) rotate(-8)">
          <path id="Path_221" data-name="Path 221" d="M14.314,0,0,12.031" transform="translate(12.993 85.066) rotate(13)" fill="none" stroke="#3bcfc3" stroke-width="3"/>
          <path id="Path_222" data-name="Path 222" d="M1012.286,540.867,997.972,552.9" transform="translate(-979.775 -444.234)" fill="none" stroke="#3bcfc3" stroke-width="3"/>
          <path id="Path_223" data-name="Path 223" d="M14.314,0,0,12.031" transform="matrix(0.995, 0.105, -0.105, 0.995, 15.495, 91.424)" fill="none" stroke="#3bcfc3" stroke-width="3"/>
          <path id="Path_224" data-name="Path 224" d="M12.469,0,0,13.13" transform="translate(12.366 62.766) rotate(42)" fill="none" stroke="#3bcfc3" stroke-width="2"/>
          <path id="Path_225" data-name="Path 225" d="M12.877,0-.839,12.855" transform="translate(12.952 78.717) rotate(22)" fill="none" stroke="#3bcfc3" stroke-width="3"/>
          <path id="Path_226" data-name="Path 226" d="M13.115,0,.41,12.756,0,13.167" transform="translate(11.527 72.755) rotate(28)" fill="none" stroke="#3bcfc3" stroke-width="3"/>
          <path id="Path_227" data-name="Path 227" d="M12.855,0,0,12.947" transform="matrix(0.819, 0.574, -0.574, 0.819, 11.375, 67.535)" fill="none" stroke="#3bcfc3" stroke-width="2"/>
          <path id="Path_228" data-name="Path 228" d="M13.567,0,0,11.753" transform="translate(11.432 59.188) rotate(42)" fill="none" stroke="#3bcfc3" stroke-width="1"/>
          <g id="Group_13" data-name="Group 13">
            <g id="Group_11" data-name="Group 11">
              <g id="Group_9" data-name="Group 9">
                <g id="Group_7" data-name="Group 7" transform="translate(70.853)">
                  <path id="Path_214" data-name="Path 214" d="M1044.839,456.753s47.957-1.927,62.535,49.96-32,78.767-32,78.767-9.22,5.9-30.053,8.625" transform="translate(-1044.839 -454.625)" fill="none" stroke="#3bcfc3" stroke-width="1"/>
                  <path id="Path_215" data-name="Path 215" d="M1045.019,576.51v5.926" transform="translate(-1044.817 -439.831)" fill="none" stroke="#3bcfc3" stroke-width="1"/>
                  <path id="Path_216" data-name="Path 216" d="M1044.839,454.858v5.01" transform="translate(-1044.839 -454.858)" fill="none" stroke="#3bcfc3" stroke-width="1"/>
                </g>
                <g id="Group_8" data-name="Group 8">
                  <path id="Path_214-2" data-name="Path 214" d="M1111.2,456.753s-48.689-1.927-63.49,49.96,32.485,78.767,32.485,78.767,9.361,5.9,30.512,8.625" transform="translate(-1044.839 -454.625)" fill="none" stroke="#3bcfc3" stroke-width="1"/>
                  <path id="Path_215-2" data-name="Path 215" d="M1045.019,576.51v5.926" transform="translate(-978.865 -439.831)" fill="none" stroke="#3bcfc3" stroke-width="1"/>
                  <path id="Path_216-2" data-name="Path 216" d="M1044.839,454.858v5.01" transform="translate(-978.48 -454.858)" fill="none" stroke="#3bcfc3" stroke-width="1"/>
                </g>
              </g>
              <g id="Group_10" data-name="Group 10" transform="translate(65.704 12.642)">
                <path id="Path_217" data-name="Path 217" d="M1040.4,466.109v4.768" transform="translate(-1040.237 -466.109)" fill="none" stroke="#3bcfc3" stroke-width="1"/>
                <path id="Path_218" data-name="Path 218" d="M1044.973,466.405v4.881" transform="translate(-1039.673 -466.073)" fill="none" stroke="#3bcfc3" stroke-width="1"/>
                <path id="Path_219" data-name="Path 219" d="M1040.255,561.467v5.042" transform="translate(-1040.255 -454.331)" fill="none" stroke="#3bcfc3" stroke-width="1"/>
                <path id="Path_220" data-name="Path 220" d="M1044.647,561.808v4.775" transform="translate(-1039.713 -454.288)" fill="none" stroke="#3bcfc3" stroke-width="1"/>
              </g>
            </g>
            <path id="Path_230" data-name="Path 230" d="M985.978,566.6l7.785-6.158" transform="translate(-982.38 -441.815)" fill="none" stroke="#3bcfc3" stroke-width="5"/>
            <path id="Path_231" data-name="Path 231" d="M985.978,566.6l7.785-6.158" transform="translate(-861.039 -545.179)" fill="none" stroke="#3bcfc3" stroke-width="5"/>
          </g>
          <g id="Group_15" data-name="Group 15" transform="matrix(-0.616, -0.788, 0.788, -0.616, 110.215, 115.521)">
            <path id="Path_221-2" data-name="Path 221" d="M14.411,0,0,11.284" transform="translate(7.691 12.881) rotate(13)" fill="none" stroke="#3bcfc3" stroke-width="3"/>
            <path id="Path_222-2" data-name="Path 222" d="M15.5,0,0,9.476" transform="translate(11.505 25.406)" fill="none" stroke="#3bcfc3" stroke-width="3"/>
            <path id="Path_223-2" data-name="Path 223" d="M15.526,0,0,10.479" transform="matrix(0.995, 0.105, -0.105, 0.995, 9.202, 19.335)" fill="none" stroke="#3bcfc3" stroke-width="3"/>
            <path id="Path_225-2" data-name="Path 225" d="M13.434,0,0,12.16" transform="translate(7.139 6.14) rotate(22)" fill="none" stroke="#3bcfc3" stroke-width="3"/>
            <path id="Path_226-2" data-name="Path 226" d="M12.822,0,0,13.142" transform="matrix(0.883, 0.469, -0.469, 0.883, 6.412, 0.323)" fill="none" stroke="#3bcfc3" stroke-width="3"/>
          </g>
        </g>
      </svg> */}
  
      <img src={center} alt="" className={"center" + start}/>

      </>
    )
}
