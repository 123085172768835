import React from 'react'
import drawing from '../../assets/drawing.png'
import Brain from './Brain'

export default function Drawing({data}) {
    const {move, fade} = data
    return (
        <div style={{opacity:fade>=0 && false? fade:''}} className='drawing'>
            <Brain data={data} />
        <img src={drawing} 
        style={{right:move&& false?`calc(${move} + 30px)`:'',
        bottom:move? -move*50+'%':'',
        opacity:fade>=0&& false? fade:''}} alt=""
        
        />
        {/* <DrawingDebris /> */}
        </div>
    )
}
