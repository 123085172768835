import React from 'react'
import './debris.scss'

export default function DrawingDebris({opacity}) {
     opacity *= 16

    const getopacity = (num)=>{
      return num -opacity >= 0? num-opacity :'0' 
    }
    return (
        <div className="debris">
            <svg id='d6'  style={{opacity:opacity? getopacity(11) :''}} xmlns="http://www.w3.org/2000/svg" width="36.101" height="33.856" viewBox="0 0 36.101 33.856">
                <path id="Path_327" data-name="Path 327" class="cls-1" d="M1228.4,525.253V507.1l26.685-4.776Z" transform="translate(-1326.355 5.819) rotate(-22)"/>
            </svg>   
            <svg id='d7' style={{opacity:opacity? getopacity(9) :''}}xmlns="http://www.w3.org/2000/svg" width="43.709" height="29.248" viewBox="0 0 43.709 29.248">


                 <path id="Path_328" data-name="Path 328" class="cls-1" d="M1181.165,490.168l-25.958-27.64,41.305,9.54Z" transform="translate(-1153.715 -461.67)"/>
            </svg>
            <svg id='d8' style={{opacity:opacity? getopacity(10) :''}} xmlns="http://www.w3.org/2000/svg" width="68.09" height="65.955" viewBox="0 0 68.09 65.955">
  
  <path id="Path_348" data-name="Path 348" class="cls-1" d="M1019.2,427.718,972.736,452.38h67.725Z" transform="translate(-402.379 -975.598) rotate(43)"/>
</svg>
<svg id='d9' style={{opacity:opacity? getopacity(8) :''}} xmlns="http://www.w3.org/2000/svg" width="5.323" height="14.274" viewBox="0 0 5.323 14.274">
  <path id="Path_333" data-name="Path 333" class="cls-1" d="M1247.115,397.85V386.607l-4.192,3.939Z" transform="translate(-1242.292 -385.451)"/>
</svg>
<svg id='d10' style={{opacity:opacity? getopacity(7) :''}} xmlns="http://www.w3.org/2000/svg" width="27.646" height="55.986" viewBox="0 0 27.646 55.986">
  
  
  <path id="Path_349" data-name="Path 349" class="cls-1" d="M1182.083,353.718V317.591l26.478,36.126L1189.5,371.2Z" transform="translate(-1181.583 -316.063)"/>
</svg>
<svg id='d11' style={{opacity:opacity? getopacity(6) :''}} xmlns="http://www.w3.org/2000/svg" width="57.547" height="37.201" viewBox="0 0 57.547 37.201">
  <path id="Path_352" data-name="Path 352" class="cls-1" d="M1275.478,327.769V291.943l55.419,15.33Z" transform="translate(-1274.978 -291.286)"/>
</svg>
<svg id="d12"style={{opacity:opacity? getopacity(4) :''}} xmlns="http://www.w3.org/2000/svg" width="6.491" height="8.818" viewBox="0 0 6.491 8.818">
  <path id="Path_332" data-name="Path 332" class="cls-1" d="M1472.459,425.552l6.247,4.331h-6.247Z" transform="matrix(0.087, -0.996, 0.996, 0.087, -551.271, 1437.665)"/>
</svg>
<svg id="d13"style={{opacity:opacity? getopacity(5) :''}} xmlns="http://www.w3.org/2000/svg" width="56.663" height="49.369" viewBox="0 0 56.663 49.369">

  <path id="Path_329" data-name="Path 329" class="cls-1" d="M1413.026,359.034h49.18L1447.3,382.5Z" transform="translate(-955.825 -1086.489) rotate(34)"/>
</svg>

<svg id="d14" style={{opacity:opacity? getopacity(3) :''}} xmlns="http://www.w3.org/2000/svg" width="62.581" height="31.138" viewBox="0 0 62.581 31.138">

  <path id="Path_353" data-name="Path 353" class="cls-1" d="M1529.675,371.2l61.926-20.22v29.66l-20-9.44Z" transform="translate(-1529.52 -350.294)"/>
</svg>

<svg id="d15" style={{opacity:opacity? getopacity(2) :''}} xmlns="http://www.w3.org/2000/svg" width="8.345" height="5.786" viewBox="0 0 8.345 5.786">
  <path id="Path_331" data-name="Path 331" class="cls-1" d="M1472.459,425.552l6.247,4.331h-6.247Z" transform="translate(-1471.959 -424.597)"/>
</svg>
<svg id="d16" style={{opacity:opacity? getopacity(1) :''}} xmlns="http://www.w3.org/2000/svg" width="33.112" height="18.563" viewBox="0 0 33.112 18.563">

  <path id="Path_330" data-name="Path 330" class="cls-1" d="M1495.782,462.234l31.786-8.493-21.948,17.424Z" transform="translate(-1494.767 -453.258)"/>
</svg>

        </div>
    )
}
