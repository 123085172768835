import React, {useMemo} from 'react'
import Skill from '../circle/skill/Skill'
import getWidnowDimentions from '../../functions/getWindowDimentions'


const styles = {
    desktop:[ {marginTop:'150px' }, {marginTop:'100px' } ],
    mobile:[ {marginRight:'', marginTop:'80px',marginBottom:'0px' }, {marginRight:'100px' , marginTop:'80px',marginBottom:'0px' } ]

}


export default function SkillsSection() {
    const {width} = getWidnowDimentions() 
    const devise = useMemo(() => {
        if( width <= 480 ){
            return 'mobile'
        }else{ 
            return 'desktop'
        }
    },[width]) 
    return (
        <div  className={"skills "} >
        <h2 className="topSkills section-title">Top<br/>Skills</h2>
        <div className="skillsList">
            <Skill style={styles[devise][0]}>Web Development</Skill>
            <Skill style={styles[devise][1]} title={{paddingTop:'40%'}} >Robotics</Skill>
            <Skill style={styles[devise][0]}><span className="gameDev">Game Development</span></Skill>
        </div>
    </div>
    
        
    )
}
