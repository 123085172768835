import React, {useState, useEffect, useContext} from 'react';
import './landingPage.scss';
import Debris from '../../components/debris/Debris';
import Drawing from '../../components/drawing/Drawing';
import throttle from '../../functions/throttle';
import Home from '../home/Home';
import game from '../../components/game/GameContext';
import Game from '../../components/game/Game';
import Typist from 'react-typist';
import getWindowDimentions from '../../functions/getWindowDimentions';

export default function LandingPage() {

    const {width} = getWindowDimentions()
    let titleTop  = {num:0, unit: ''}
    if( width < 600){
        titleTop = {num:130, unit: 'px'}
    }else if (width < 800){
        //170px
        titleTop = {num:170, unit: 'px'}

    }
    else{
        titleTop = {num:250, unit: 'px'}
    }
    // const history = useHistory()

    const [opacity, setOpacity] = useState(0);
    const [drawing, setDrawing ] = useState({});
    const [perce, setperce] = useState(0);
    // const [snap, setSnap] = useState(false)
    const [page, setPage] = useState('home');
    const [goUp, setGoUp] = useState(true);
    const [start] = useContext(game);
    const scrollUp = () => {
        setGoUp(false)
        window.scrollTo(0, 0)
    }
    const scrolling = () => { 
        // first transition
        let perc = window.pageYOffset
        var limit = document.body.offsetHeight - window.innerHeight;
        perc = ( perc / limit )* 100
        // console.log(perc);
        setperce(perc)
        if( perc <= 60){ 
            setDrawing({top:0})
            setOpacity((perc )/60)
            setPage('home')

        }
       else if(perc < 95){
            setOpacity(1)
            setDrawing({...drawing, fade: 1-((perc - 60)/50), move:(perc-60)/20, top: (perc-60)/40})
            setPage('home')
        }
      
        else if(perc >= 95 && page === 'home' ){
            //change page
            setOpacity(1);
            setDrawing({ move:2, fade:0, size:100, top:1});
            // alert('out bitches !! ');
            // history.push('home') // fex the back bug pleaaase do not forget it :)) (future yazeed here i Don't know what you are talking about :P )
            setPage('info');
        }
        // console.log(perc)
    }

    if(start){
        window.removeEventListener('scroll', throttle(scrolling, 10));
    }
    useEffect(() => {
        scrollUp();
        window.addEventListener('scroll' , throttle( scrolling, 10) );
        return () => {
            // cleanup
            window.removeEventListener('scroll', throttle(scrolling, 10));
        }
    }, [])
    if(perce === 0 && page !=='home'){

        setPage('home')
    }
    return (
        <>
        
        <div className={"landingPage "+ start}>
     
         {page === 'home' && !start? <>
         {!start? 
         <>
<h1 className="mainTitle" style={{top:drawing?(titleTop.num  -( drawing.top* 70 * (titleTop.unit === 'px'? 7: 1) )) + titleTop.unit:''}} >YAZEED <br/> ALMARWANI <br/>
            <span className="typing"><Typist>Full-stack Developer
                
            <Typist.Backspace count={20} delay={1400} />
            <span>Robotics enthusiast</span>
            <Typist.Backspace count={21} delay={1400} />
            <span>Creator.</span>
                </Typist></span></h1>            
            <Debris opacity={opacity} />
            <Drawing data={drawing}/>
            </>
         :''}
            </>
         
            : 
            <>
        <Home/>
        </>
        }
       {start === ' start remove'? 
       <Game />
       :''}
        
        </div>
        
        </>
    )

}
