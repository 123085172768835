import React, { useState, useContext}from 'react'
import './navbar.scss'
import logo from '../../assets/logo.png'
import { useHistory, Link } from 'react-router-dom'
import game from '../game/GameContext'
import getWindowDimentions from '../../functions/getWindowDimentions'

export default function NavBar() {
    const history = useHistory()
    const {width} = getWindowDimentions()
    const [path, setPath] = useState(history.location.pathname)
    const [start] = useContext(game)
    const [list, setList] = useState(width > 600 )
    const [intialList, setInitialList] = useState(true )
    const go = (link) => {
        if(!start){
            setPath(link)
            if (width <= 600){
                 setList(false)
            }
            history.push(link)
            
             
        }
    }
    const displayList  = () => { 

        setList(!list)
        setInitialList(false)
    }
    return (<>
        <div className={"logo"+ start} onClick={()=> go('/')}>
        <img src={logo} alt=""/>
    </div>

        <nav className={"navbar " + start }>
            <div className={"burgerMenue "+ (list?'x': '')} onClick={displayList} >
 {!intialList? <>
                 <div className={"line-x1 " + (list?'appear':'close')}></div>
                 <div className={"line-x2 " + (list?'appear':'close')}></div> 
                 </>
 : ''}

                <div className={"line one " + (!intialList? (list? 'close':'appear'):'')}></div>
                <div className={"line two " + (!intialList? (list? 'close':'appear'):'')}></div>
                <div className={"line three " + (!intialList? (list? 'close':'appear'):'')}></div>
           
            
                
            </div>
           

            <ul className={list? 'displayList': 'removeList'}>
           
          {/* <li className={path === '/projects'? 'active':''} onClick={()=>go('/projects')}>Projects</li> */}
            {/* <li className={path === '/skills'? 'active':''} onClick={()=>go('/skills')}>Skills</li> */}
            <li className={path === '/contactMe'? 'active':''} onClick={()=>go('/contactMe')}>Contact Me</li>

                
            </ul>


        </nav>
        {width <= 600 && !intialList? 

<ul className={'list ' + (list && !start? 'displayList': 'removeList')}>

{/* <li className={path === '/projects'? 'active':''} onClick={()=>go('/projects')}>Projects</li> */}
{/* <li className={path === '/skills'? 'active':''} onClick={()=>go('/skills')}>Skills</li> */}
<li className={path === '/contactMe'? 'active':''} onClick={()=>go('/contactMe')}>Contact Me</li>

    
</ul>
:''}
        </>
    )
}
