import React, {useContext}from 'react'
import './movingCircle.scss'
import Center from './parts/Center'
import Outer from './parts/Outer'
import Middel from './parts/Middel'
import game from '../game/GameContext'
export default function MovingCircle({animation}) {
    const [start] = useContext(game )
    return (
        <div className="marginCircle">
        <div  className={"movingCircle "+ (animation? 'animation': '')+start} style={{}}>
           <Center start={start} />
            <Middel start={start} /> 
            <Outer start={start} />


        </div>
        </div>
    )
}
