import React, { useReducer } from 'react'
import { useEffect } from 'react'

export function SquareAuto({speed, direction , place, gameOver, id}) {

    const moveSquare = (state, action)=>{
        return {...state, [direction] : state[direction] + speed}  
    }
    const [location, move] = useReducer( moveSquare, {...place, [direction]:0})
    useEffect(() => {

        if(!(speed &&  direction && place && gameOver)){
            throw new Error('missing parameters in a square'  );
        }        
        const interval = setInterval(() => {

            move()
        }, 33);
        return () => clearInterval(interval);
      }, []);
    return (
        <div className="square" key={id} onMouseEnter={gameOver} style={location}>
             
        </div>
    )
}


export default function Square({gameOver, location}){

    return(
        <div className="square" onMouseEnter={gameOver} style={location}>
             
        </div>
    )
}