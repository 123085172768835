import React, {useState, useContext, useEffect} from 'react'
import './gameOver.scss'
import backImg from '../../assets/back.svg'
import ReactInput from 'react-code-input'
import './gameOver.scss'
import gameContext from './GameContext'
import link from '../../link'
// import Axios from 'axios'

const color = '#D4D4D4'

export default function GameOver({score, playAgain}) {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [submited, setSubmited] = useState(false)
      const call = async() => {
        // let scores = await firestore().collection('brokenMachine').limit(10).get()
        // scores = scores.docs
        // scores = scores.map(score => score.data())

        // let scores = await Axios.get(link+ 'score' )
        // setData(scores.data)
    }
    useEffect(() => {
    
        call() 
     

    }, [])
    const [, setStart] = useContext(gameContext)
    const [initials, setInitials] = useState('')
    const [add, setAdd] = useState(false)
    const onInputChange = (e) => { 
        if(e.length >= 3){
            setAdd(true)
        }else{ 
            setAdd(false)

        }
        setInitials(e)
    }
    const submit = async () => {
        if (initials.length === 3){
            try{
            setLoading(true)
            try{ 

                // await Axios.post(link+'score', {score, initials:initials.toUpperCase()})
            }
            catch (err) { 
                console.log(err.response)
            }
            call()
            setSubmited(true)
            setLoading(false)
            
        }

            catch (err){ 
                console.log(err);
            }
        }
    }
    const back = () => {
        setStart('back')
    }
    return (
        <div className='gameOver'>
            <img className='back' src={backImg} alt="" onClick={back}/>
            {/* <svg className='back' xmlns="http://www.w3.org/2000/svg" width="46.411" height="49.571" viewBox="0 0 46.411 49.571"> */}
  {/* <path id="Path_354" data-name="Path 354" d="M8574.31-107.516l-40.512,22.5L8574.31-64.7" transform="translate(-8531.298 110.915)" fill="none" stroke="#00ffef" stroke-linecap="round" stroke-linejoin="round" stroke-width="5"/>
</svg> */}

{/* add this later VVVV  */}
  {/* <button className="selfDistruct">self-<br/>distruct</button>  */}

            <h1 className="score">Your score is: {score}</h1>
            {submited? "":
            <>
        <h2 className="initails">Add your initials</h2>
            <ReactInput fields={3} onChange={onInputChange} inputStyle={{backgroundColor:'rgba(0,0,0,0)', width:'30px', height:'35px', margin:'10px', border:`${color} 2px solid`, borderRadius:'7px', color:color,fontSize:'25px', textAlign:'center', textTransform:'uppercase', marginBottom:add? '10px': '69px'}} />
            {add? 
            <button className='submitScore' onClick={loading?'':submit} style={{cursor:loading? 'wait':''}}>Submit score</button>
            :''}
            </>
            }
           
            <div className="scores">
                <h3 className="topScores">Top scores</h3>
                <table>
  <tr>
    <th>Place</th>
    <th>Initials</th>
    <th>Score</th>
  </tr>
        {data.map((elm, i)=> <tr>
    <th>{i}</th>
    <th>{elm.initials}</th>
    <th>{elm.score}</th>
  </tr>)}
  </table>
  </div>

  <button className="playAgian" onClick={playAgain}>Play again</button>
        </div>
    )
}
