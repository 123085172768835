import React, {useState} from 'react'
import './contactMe.scss'
import { FaLinkedinIn, FaGithub, FaTwitter} from "react-icons/fa";
import  './contactMe.scss'
import axios from 'axios';
import ReactLoading from 'react-loading'
import link from '../../link'

const blue = '#00FFEF'
export default function ContactMe() {
    const [state, setState] = useState({})
    const [loading, setLoading] = useState(false)
    const onChange = (e)=> {
        setState({...state, [e.target.name]: e.target.value});
    }
 
    const onSubmit = (e)=> {
        e.preventDefault();

        // start loading 
        if (loading) return
        if (!(state.name && state.email && state.message)){ 
            alert('please fill all of the fields')
            return
        }
        //send request
        setLoading(true)
        axios.post(link+'email', state).then(()=> {
            // stop loading
            setState({name:'', email:'', message:''})
            setLoading(false)
            // alert done
            alert('Your message is received you will receive a reply shortly')
           
        }).catch((err)=> {
            // stop loading 
            setLoading(false)
            // alert for errors
            alert('something went wrong :( please try agian later')
        })
    }
    return (
        <div className="contactMe">
            <div className="contacts">

           
            <h1>Contact Me</h1>
            <h3>lets make something amazing!</h3>
            <div className="icons"> 
            
                <div className="icon" > 
                <a href="https://twitter.com/YazeedAlmarwani"  target="_blank" >
                    <FaTwitter size={35}  color={blue} />
                    </a> 
                </div>
                <div className="icon">
                     <a href="https://www.linkedin.com/in/yazeedalmarwani/"  target="_blank" >

                    <FaLinkedinIn size={35} color={blue} />
                    </a>

                </div>
                <div className="icon">
                <a href="https://github.com/Yazeed2"  target="_blank" >

                    <FaGithub size={35} color={blue} /> 
                    </a>
                </div>


            </div>

            <form calssName='emailForm' style={{cursor: loading? 'wait': ''}} onSubmit={(e)=>onSubmit(e)}  >
                <h4>Send me an email       {loading? 
                <div className="loading">
                    
                 <ReactLoading type={'spin'} color={blue} height={30} width={30}  />
                 </div>

: ''}</h4> 
                <div className="inputs">
                <input onChange={onChange}
                name="name"
                value={state.name}
                style={{cursor: loading? 'wait': ''}}
                className="name" type="text" placeholder="Name *" />
                <input   
                onChange={onChange}
                value={state.email}

                style={{cursor: loading? 'wait': ''}}
                name="email" type="email" placeholder="Email *" />
                </div>
   
                <textarea onChange={onChange} 
                value={state.message}

                style={{cursor: loading? 'wait': ''}}
                name="message" cols="15" rows="10" placeholder="Message *"></textarea>
                <button type='submit' className='submit' style={{cursor: loading? 'wait': ''}}>Send</button>
           
            </form>
            </div>
      
        </div>
    )
}
