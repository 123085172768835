import React from 'react'
import './debris.scss';
import DrawingDebris from './DrawingDebris';
export default function Debris({opacity}) {


    const getopacity = (num)=>{
      return num -opacity*16 >= 0? num-opacity*16 :'0' 
    }
    return (
      <div className="debris">

        <svg id="d1"  style={{opacity:opacity? getopacity(12) :''}} xmlns="http://www.w3.org/2000/svg" width="91.672" height="89.528" viewBox="0 0 91.672 89.528">       
        <path id="Path_347" data-name="Path 347" class="cls-1" d="M738.465,397.85l90.47-58.157L768.327,428.02Z" transform="translate(-737.676 -339.273)"/>        </svg>


        <svg id='d2'  style={{opacity:opacity? getopacity(16) :''}}  xmlns="http://www.w3.org/2000/svg" width="84.72" height="107.195" viewBox="0 0 84.72 107.195">
  <path id="Path_344" data-name="Path 344" class="cls-1" d="M189.475,667.112,132.714,771.994l82.892-41.065Z" transform="translate(-131.524 -665.946)"/>
</svg>

<svg  id='d3' style={{opacity:opacity? getopacity(14) :''}}  xmlns="http://www.w3.org/2000/svg" width="14.866" height="18.511" viewBox="0 0 14.866 18.511">
  
  <path id="Path_345" data-name="Path 345" class="cls-1" d="M317.794,639l13.323,16.59V639Z" transform="translate(-316.752 -638.5)"/>
</svg>
<svg id="d4"  style={{opacity:opacity? getopacity(13) :''}}  xmlns="http://www.w3.org/2000/svg" width="131.128" height="84.431" viewBox="0 0 131.128 84.431">

  <path id="Path_343" data-name="Path 343" class="cls-1" d="M498.579,677.988l129.465,72.564-88.539-83.44Z" transform="translate(-497.259 -666.558)"/>
</svg>
<svg id="d5" style={{opacity:opacity? getopacity(15) :''}}  xmlns="http://www.w3.org/2000/svg" width="48.401" height="86.715" viewBox="0 0 48.401 86.715">
 
  <path id="Path_346" data-name="Path 346" class="cls-1" d="M324.456,893.144l15.655,72.008,31.351-84.218Z" transform="translate(-323.865 -880.209)"/>
</svg>
<DrawingDebris opacity={opacity}/>
      </div>
      
      
    )
}