import React from 'react'
import coffeeImg from '../../assets/coffee.png'

export default function CreativitySection() {
    return (
        <div className='creativity-section section'>
             <div className="section-image creativity">
                <img src={coffeeImg} alt=""/>
        </div>
        <div className="text-section">
        <h2 className="section-title">fuel<br/>Your creativity</h2>
        <h4 className="section-subtitle">Sip on inspiration </h4>
        <p className="section-paragraph">
        With a cup of coffee and a dive into diverse fields, I ignite my creativity. This unique blend of experiences and perspectives fuels innovative problem-solving and keeps my work fresh and exciting, allowing me to approach challenges with a creative edge.</p>
        </div>
       
    </div>

    )
}
