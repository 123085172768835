import React, {useEffect, useState, useContext, useMemo} from 'react'
import './home.scss'
import MovingCircle from '../../components/circle/movingCircle'
import Video from '../../components/video/Video'
import "animate.css/animate.min.css";
import game from '../../components/game/GameContext'
import SkillsSection from '../../components/skillsSection/SkillsSection'
import ProjectSection from '../../components/projectSection/ProjectSection'
import CreativitySection from '../../components/creativitySection/CreativitySection'
import useWindowDimensions from '../../functions/getWindowDimentions'
import getDevice from '../../functions/getDevice'

export default function Home() {
    const [appear, setApear] = useState(false)
    const [start, setStart] = useContext(game)
    const {width} = useWindowDimensions();
    const devise = useMemo(() => getDevice(width),[width])
    console.log(devise) 
    useEffect(() => {

        setTimeout(() => {
            setApear(true )
        }, 4000);
    }, [])
    return (
        <div className={'homePage '+ start} > 
        <div className='content-wrapper' >
        <button className={"selfDistruct "+ start} onClick={()=>setStart('start')}>self-<br/>distruct</button>
        {devise !== 'desktop'? <div>
        <MovingCircle />
        <Video />
        </div>
            :''}
        <div className="aboutMe-section">

        <div className={"aboutMe " + start}>

            <h3>About me</h3>
            <p>My name is Yazeed Almarwani. I am a college student at day a software head at night on my weekends… well, I make robots that may or may not go out of hand and hit me in the face.</p>
        </div>
        <div>
            {devise === 'desktop'? <>
        <MovingCircle />
        <Video />
        </>
            :''}
        </div>
       
    
        </div>
        
        {!start?
         
        <>
        {appear? 
        <> 
    <SkillsSection /> 
        

<ProjectSection /> 
<CreativitySection /> 



</> 

        :""}
        </>
        :''}
  
  </div> 
        </div>
    )
}
