import React, {useState, useEffect, useReducer} from 'react'
import Square from './entities/Square'
import getWindowDimentions from '../../functions/getWindowDimentions'


const random = (min, max)=> {
    return Math.floor(Math.random() * (max - min + 1) + min);
}

const isMobileVersion = () => {
    return ( 'ontouchstart' in window ) || ( navigator.maxTouchPoints > 0 ) || ( navigator.msMaxTouchPoints > 0 ) ||  navigator.userAgent.indexOf("Safari") != -1;
}

const directions = ['right', 'left', 'top', 'bottom']
export default function Render({gameOver}) {
    var interval 
    const { height , width } = getWindowDimentions();
    const getMax = (direc) => {
        if(direc === 'right' || direc === 'left'){
            return width
        }
        else if(direc === 'top' || direc === 'bottom'){
            return height
        }
        else{
            throw new Error('direction not found :( ')
        }
    }
    const [render, setRender] = useState([])
    // const [state, setState] = useState({})s
    const [direction, setDirection] = useState('')
    const accelerate = (state )=> {
            if(state >= 100){
                return 100
            }else
            return state + 3

    } 
    const [speed, changeSpeed] = useReducer(accelerate,12)
    const [wait, setWait] = useState(false)
    const incrementDisplacment = (state, action) => {
        // console.log('i like to move it move it ');
        if(!render.length){
             return state
        }else if(getMax(direction) < state)
        { 
            setWait(true)
            renderMore()

            return -100 
        }
        else if (!wait) { 

            return state + speed

        }else {
            return 0
        }

    }
    const [displacment, move] = useReducer(incrementDisplacment, 0)
    function getPlacing (direc){
        if(direc === 'right' || direc === 'left'){
            return 'top'
        }
        else{
            return 'left'
        }
    }
    function renderMore(){
        // setState({...state, speed:10, direction:'right', place:{right:0}})
        setTimeout(() => {
            setWait(false)
        }, (73 - speed ) * 200/6 );

        let dir = random(0,3)
        let direc = directions[dir]
        setDirection(direc)
        let loc = getPlacing(direc)
        changeSpeed()
        let num = random(3, 20)
        let arr = []
        let marginValues = [30]
        for(let i=0; i<num ; i++){
            let thisMargin = marginValues[marginValues.length - 1 ]
            thisMargin += random(90, 160)
            marginValues.push(thisMargin)
            arr.push({[loc]:thisMargin})
            // console.log({[loc]:(((i+1) * 100)+ 10)});

            // =.push({margin: random(80, 100)+ 'px'})
        }
        setRender(arr)
    }

    useEffect(() => {
        setTimeout(() => {
            renderMore()
         interval = setInterval(() => { move()}, 33);
        return () => clearInterval(interval);
        }, 1000);
        
      }, []);

    return (
        <div>
            {!wait? 
            <>
            {render && render.map((comp, i) => { 
                return <Square location={{...comp, [direction]: displacment}} gameOver={gameOver} id={i} /> 
            })}
            </>
            :''}
            
        </div>
    )
}
