import React, { useEffect, useState, useContext, useMemo } from "react";
import frame from "../../assets/frame.png";
import useWindowDimensions from "../../functions/getWindowDimentions";
import style from "../../pages/home/home.scss";
import game from "../game/GameContext";
import glitch from "../../assets/glitch.gif";
import getDevice from '../../functions/getDevice'


export default function Frame({ vidStyle }) {
  const { width } = useWindowDimensions();
  const [top, setTop] = useState(null);
  const [appear, setApear] = useState(false);
  const [start] = useContext(game);
  const devise = useMemo(() => getDevice(width),[width]) 
  if (start && appear) {
    setTimeout(() => {
      setApear(false);
    }, 400);
  }
  useEffect(() => {
    setTimeout(() => {
      setApear(true);
    }, 4000);
  }, []);

  const videoStyle = { 
    desktop: [{
      position: "relative",
      top: `-322px`,
      zIndex: 1,
      right: `-162px`,
      width:500,
      height:((500) / 16) * 9 ,
      // (width* 0.45)* 0.1415 + 200
    }, {
  
      position: "relative",
      top: `-322px`,
      zIndex: 1,
      right: `-162px`,
      width:500,
      height:((500) / 16) * 9 ,
      top: `-${300 *2}px`,
      zIndex: 1,
  
    }
  
  ],
    mobile: [{
      position: "relative",
      top: `-170px`,
      zIndex: 1,
      margin: ' 0 auto',
      width:260,
      
      height:((260) / 16) * 9 ,
      marginBottom: '0'
    },
      {
        position: "relative",
        top: `-${157 *2}px`,
        zIndex: 1,
        margin: ' 0 auto',
        width:260,
        left: '40px',
        height:((260) / 16) * 9 ,
      }
    ] ,
    tablet: [{
      position: "relative",
      top: `-322px`,
      zIndex: 1,
      right: `-135px`,
      width:500,
      height:((500) / 16) * 9 ,
      // (width* 0.45)* 0.1415 + 200
    }, {
  
      position: "relative",
      top: `-322px`,
      zIndex: 1,
      right: `-135px`,
      width:500,
      height:((500) / 16) * 9 ,
      top: `-${300 *2}px`,
      zIndex: 1,
  
    }
  
  ]
  }
  return (
    <div className="video">
      <div className="frame-container">

      <img
        src={frame}
        alt=""
        className={"frame " + start}
        style={{ top: top ? top : "" }}
      />
      </div>

      {width && appear ? (
        <div className="video-container">
        <iframe 
          title="introduction video"
          width={videoStyle[devise][0].width}
          height={videoStyle[devise][0].height}

          style={
            vidStyle  || videoStyle[devise][0]
          }
          src="https://www.youtube.com/embed/47yz_1We_uE"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
        </div>
      ) : (<>
       {devise === 'mobile'?  <div style={{marginBottom: '340px'}}></div>: ''}
       {devise === 'tablet'?  <div style={{marginBottom: '650px'}}></div>: ''}

       </>
      )}
      {start && appear ? (
        <img
          src={glitch}
          style={
            
            vidStyle || videoStyle[devise][1]
          }
          alt=""
        />
      ) : (
        ""
      )}
    </div>
  );
}
// {width?
//   <iframe width={(width*0.45)- ((width*0.9))* 0.1415} height={((width * 0.45)/16 * 9) - ((width* 0.45)/16 * 18)* 0.1415} className="video" style={vidStyle || { float:'right',
// marginTop:`-${(((width * 0.45)/16 * -9)* 0.1415) + 300 +'px'} ` ,zIndex:1 , marginRight:`calc(${ (width* 0.45)* 0.1415 + 'px'} + ${style.frameRightMargin})`, marginLeft:'auto'  }} src="https://www.youtube.com/embed/47yz_1We_uE" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
// : ''}
